export enum SubscriptionType {
  GIFTABLE_SUBSCRIPTION = "giftable-subscriptions",
  OFFER_REDEMPTION = "offer-redemption"
}

export enum GiftableSubscriptionStatus {
  PENDING = "pending",
  SENT = "sent",
  CLAIMED = "claimed"
}

export type GiftableSubscription = {
  id: number;
  type: SubscriptionType;
  status: GiftableSubscriptionStatus;
  subscriptionId: string;
  recipientName: string;
  recipientEmail: string;
};
