import { createInstance } from "@optimizely/react-sdk";
import { useMemo } from "react";

import { logger } from "@/logger";
import { applicationSettings } from "@/constants";

const { OPTIMIZELY_SDK_KEY } = applicationSettings;

const useOptimizelyClient = () => {
  const optimizelyClient = useMemo(() => {
    try {
      return createInstance({ sdkKey: OPTIMIZELY_SDK_KEY });
    } catch (error) {
      logger.error("Failed to create Optimizely client:", error);
      return null;
    }
  }, []);

  return optimizelyClient;
};

export default useOptimizelyClient;
