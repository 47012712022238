export enum ImpressionInternalId {
  SmsCollectionModal = 1,
  ForYouIndividualizationPill = 4,
  SearchIndividualizationBanner = 5,
  ReferralBanner = 6,
  MyPracticeTooltip = 7,
  PaidConversionAnalytics = 10
}

export interface UserImpression {
  internalId: ImpressionInternalId;
  maxViews: number;
  type: string;
  route: string;
  viewCount: number;
  impressionId: number;
}
