import { pino } from "pino";

import { loggerConfig } from "./LoggerConfig";

export const logger = pino({
  ...loggerConfig,
  browser: {
    asObject: false,
    serialize: false
  }
});
