import { FC } from "react";

import { ResponsiveContainer } from "@/ui/components";

export interface FeaturePromoHeaderProps {
  title: string;
  description?: string;
}

export const FeaturePromoHeader: FC<FeaturePromoHeaderProps> = ({ title, description }) => {
  return (
    <div className="bg-surface-default-secondary">
      <ResponsiveContainer>
        <div className="p-base md:px-0">
          <h3 className="text-h3 text-primary">{title}</h3>
          {description && <p className="mt-base max-w-2xl text-secondary lg:block">{description}</p>}
        </div>
      </ResponsiveContainer>
    </div>
  );
};
