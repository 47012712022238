export const PROGRESS_EVENT_TRACKING_INTERVAL = 30; // Track progress event once every 30 seconds

export const MESSAGE_MAX_TIMEOUT = 5000;

export const DAYS_OF_WEEK = [
  {
    day: 'Sunday',
    short: 'Sun',
    weekDay: 0,
    workday: false
  },
  {
    day: 'Monday',
    short: 'Mon',
    weekDay: 1,
    workday: true
  },
  {
    day: 'Tuesday',
    short: 'Tue',
    weekDay: 2,
    workday: true
  },
  {
    day: 'Wednesday',
    short: 'Wed',
    weekDay: 3,
    workday: true
  },
  {
    day: 'Thursday',
    short: 'Thu',
    weekDay: 4,
    workday: true
  },
  {
    day: 'Friday',
    short: 'Fri',
    weekDay: 5,
    workday: true
  },
  {
    day: 'Saturday',
    short: 'Sat',
    weekDay: 6,
    workday: false
  }
]