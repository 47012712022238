import { FC } from "react";
import Image from "next/image";

import { cn } from "@/ui/utils";

import { ResponsiveContainer } from "../ResponsiveContainer";
import { FeaturePromoHeader } from "./FeaturePromoHeader";

interface Section {
  heading: string;
  content: string;
  className?: string;
  imageUrl?: string;
  imagePosition?: "left" | "right";
}

export interface FeaturePromoOverviewProps {
  title: string;
  sections: Section[];
}

export const FeaturePromoOverview: FC<FeaturePromoOverviewProps> = ({ title, sections }) => {
  return (
    <>
      <FeaturePromoHeader title={title} />

      <ResponsiveContainer>
        <div className="mb-10">
          {sections.map((section, index) => {
            const { className, heading, content, imageUrl, imagePosition } = section;

            return (
              <div key={index} className={`block ${className}`}>
                <div
                  className={cn("grid gap-spacing-fixed-M", {
                    "[grid-template-areas:'promo-content']": !imageUrl,
                    "[grid-template-areas:'promo-image''promo-content']": imageUrl,
                    "md:grid-cols-[416px_1fr] md:[grid-template-areas:'promo-image_promo-content']":
                      imageUrl && imagePosition === "left",
                    "pl-16 md:grid-cols-[1fr_416px] md:[grid-template-areas:'promo-content_promo-image']":
                      imageUrl && imagePosition === "right"
                  })}
                >
                  {imageUrl && (
                    <div className="size-full [grid-area:promo-image] md:w-[416px]">
                      <div className="relative">
                        <Image src={imageUrl} className="object-cover" alt={heading} width={500} height={500} />
                      </div>
                    </div>
                  )}

                  <div className="[grid-area:promo-content]">
                    <div className="flex flex-col gap-mobile-contract-S pb-spacing-fixed-L pt-spacing-fixed-M">
                      <h1 className="header/h5 text-primary">{heading}</h1>
                      <p className="body text-primary">{content}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </ResponsiveContainer>
    </>
  );
};
