import { memo, useEffect, useState } from "react";
import { debounce, get } from "lodash-es";
import { usePathname } from "next/navigation";

import { User } from "@/types";
import { Badge } from "@/ui/shadcn";
import { chatClient } from "@/context/ChatContext";

type Props = { user: User | null };

const TRUNCATE_UNREAD_COUNT = 9;
const DEBOUNCE_MSG_HANDLER_MS = 300;

function Component({ user }: Props) {
  const [unreadCount, setUnreadCount] = useState<number>();
  const pathname = usePathname();

  useEffect(() => {
    (async () => {
      if (!user?.id || !chatClient.user) return;

      const unread = await chatClient.getUnreadCount(user.id);
      const totalUnreadCount = get(unread, "total_unread_count");
      setUnreadCount(totalUnreadCount);
    })();
  }, [user?.id]);

  useEffect(() => {
    const { unsubscribe } = chatClient.on(
      /**lets say user sends message fast,
       * we wont want to update state frequently, so its ideally to put debounce here */
      debounce((evt) => {
        const totalUnreadCount = get(evt, "total_unread_count");
        if (totalUnreadCount !== undefined) {
          setUnreadCount(totalUnreadCount);
        }
      }, DEBOUNCE_MSG_HANDLER_MS)
    );

    if (pathname.includes("/v2/plan")) {
      setUnreadCount(0);
      unsubscribe();
    }

    return unsubscribe;
  }, [pathname]);

  if (!user || !unreadCount) {
    return null;
  }

  return (
    <Badge variant={"rectangle-smallest"} className="px-1 py-0 text-[11px] font-medium">
      {unreadCount > TRUNCATE_UNREAD_COUNT ? "9+" : unreadCount}
    </Badge>
  );
}

export const MsgNotificationBadge = memo(Component);
