import { UserSession, UserCoachingClass, YogaClass } from '.';

export enum VideoPlayerPlatform {
  Chromecast = 'chromecast',
  Web = 'web'
}

export enum PlaybackEventType {
  Start = 'start',
  Pause = 'pause',
  Seek = 'seek',
  Progress = 'progress'
}

export interface PlaybackBaseEvent {
  classId: YogaClass['id'] | UserCoachingClass['classId'];
  event: PlaybackEventType;
  platform?: VideoPlayerPlatform;
  timecode: Array<number | string>;
}

export interface PlaybackEvent extends Omit<PlaybackBaseEvent, "timecode"> {
  clientId: UserSession['session']['passport']['user']['uuid'];
  timecode: string;
  timestamp: string;
}
