"use client";
import Link from "next/link";

import { applicationSettings } from "@/constants";
import { Logo } from "@/ui/assets/Logo";

const { LEGACY_WEBSITE_URL } = applicationSettings;

export const LogoGlo = () => {
  return (
    <div className="flex items-center">
      <Link href={LEGACY_WEBSITE_URL}>
        <Logo />
      </Link>
    </div>
  );
};
