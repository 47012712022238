import { Button, ButtonProps, Icon, IconNames, IconProps } from '@/ui/shadcn';

interface ButtonIconProps extends ButtonProps {
  iconProps?: Omit<IconProps, 'name'>;
  name: IconNames;
}

const ButtonIcon = ({ name, iconProps, ...props }: ButtonIconProps) => {
  return (
    <Button {...props}>
      <Icon name={name} {...iconProps} />
    </Button>
  );
};

export { ButtonIcon, type ButtonIconProps };
