import { YogaClass } from "./YogaClass";

export enum ForYouContentType {
  Class = "class",
  Program = "program",
  Collection = "collection"
}

export enum ForYouStyle {
  Cards = "cards",
  Feature = "feature"
}

export interface ForYou {
  id: string;
  type: string;
  name: string;
  description?: string;
  contentType: ForYouContentType;
  group: string;
  classes?: YogaClass[];
  style: ForYouStyle;
}
