"use client";

import { useEffect, useState } from "react";
import { useTheme } from "next-themes";
import { MdOutlineWbSunny, MdOutlineNightlight } from "react-icons/md";

export const ThemeToggle = () => {
  const [isMounted, setIsMounted] = useState(false);

  const { systemTheme, theme, setTheme } = useTheme();

  const currentTheme = theme === "system" ? systemTheme : theme;

  const toggleTheme = () => {
    const nextTheme = currentTheme === "light" ? "dark" : "light";
    setTheme(nextTheme);
  };

  useEffect(() => {
    setIsMounted(true);
  }, []);

  return isMounted ? (
    <div className="px-2">
      <button
        type="button"
        className="flex size-10 items-center justify-center rounded-md border border-darker p-2 text-primary transition-all duration-200 hover:bg-surface-smoked-lighter focus:ring-0"
        onClick={toggleTheme}
      >
        {currentTheme === "light" ? <MdOutlineWbSunny /> : <MdOutlineNightlight />}
      </button>
    </div>
  ) : null;
};
