"use client";
import { useEffect, useState } from "react";
import Link from "next/link";

import { cn } from "@/ui/utils";

export const ScrollToTop = () => {
  const [hidden, setHidden] = useState(true);
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleHidden = () => {
    setHidden(typeof window !== "undefined" && window.scrollY <= 300);
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleHidden);
    return () => {
      window.removeEventListener("scroll", toggleHidden);
    };
  }, []);

  return (
    <section>
      <Link
        className={cn("fixed bottom-20 right-5 h-12 w-12 rounded-full border-0 bg-white/65 p-2", hidden && "hidden")}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          scrollToTop();
        }}
      >
        <span className="leadding-none absolute inset-x-0 top-1/2 -translate-y-1/2 px-[calc(8px*var(--scale,1))] text-center text-2xl font-extrabold text-black">
          ↑
        </span>
      </Link>
    </section>
  );
};
