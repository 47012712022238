"use client";
import { uniqueId } from "lodash-es";
import Image from "next/image";
import { useEffect, useState } from "react";
import Link from "next/link";
import { ChevronLeft, ChevronRight } from "lucide-react";

import {
  Button,
  Carousel,
  CarouselApi,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious
} from "@/ui/shadcn";
import { cn } from "@/ui/utils";
import { applicationSettings } from "@/constants";
const { LEGACY_WEBSITE_URL } = applicationSettings;

const dataCarousel = [
  {
    title: "Ivorie Jenkins",
    src: "/v2/images/landing/teachers/ivorie-jenkins.webp",
    link: `${LEGACY_WEBSITE_URL}/teachers/ivorie-jenkins`
  },
  {
    title: "Jason Crandell",
    src: "/v2/images/landing/teachers/jason-crandell.webp",
    link: `${LEGACY_WEBSITE_URL}/teachers/jason-crandell`
  },
  {
    title: "Jo Tastula",
    src: "/v2/images/landing/teachers/jo-tastula.webp",
    link: `${LEGACY_WEBSITE_URL}/teachers/jo-tastula`
  },
  {
    title: "Kia Miller",
    src: "/v2/images/landing/teachers/kia-miller.webp",
    link: `${LEGACY_WEBSITE_URL}/teachers/kia-miller`
  }
];

export const Teachers = () => {
  const [carouselApi, setCarouselApi] = useState<CarouselApi>();
  const [slideIndex, setSlideIndex] = useState(0);

  useEffect(() => {
    if (!carouselApi) {
      return;
    }
    setSlideIndex(carouselApi.selectedScrollSnap());
    carouselApi.on("select", () => {
      setSlideIndex(carouselApi.selectedScrollSnap());
    });
  }, [carouselApi, setCarouselApi]);

  return (
    <section id="teachers" className="bg-white py-16 dark:bg-surface-primary md:py-[120px]">
      <div className="responsive-container lg:container">
        <h2 className="mx-auto mb-8 px-4 text-[32px] font-medium leading-tight md:mb-16 md:max-w-[600px] md:px-8 md:text-center md:text-[40px] lg:max-w-full xl:text-5xl">
          Learn from a blend of tradition and innovation
        </h2>
        <Carousel setApi={setCarouselApi} className="w-full" opts={{ loop: false, align: "start", slidesToScroll: 1 }}>
          <CarouselContent
            className={cn(
              "ml-0 flex transition-transform duration-1000 ease-in-out",
              `translate-x-[-${slideIndex * 100}%]`
            )}
          >
            {dataCarousel.map((item) => (
              <CarouselItem key={uniqueId(item.title)} className="pl-0 md:basis-[29%] lg:basis-1/4">
                <Link href={item.link} target="_blank" className="flex justify-center">
                  <Image
                    src={item.src}
                    alt={item.title}
                    width={500}
                    height={500}
                    loading="lazy"
                    className="mb-8 max-md:max-w-[400px]"
                  />
                </Link>
                <h3 className="text-center text-xl font-medium leading-none">{item.title}</h3>
              </CarouselItem>
            ))}
          </CarouselContent>
          <CarouselPrevious className="left-4 size-12 rounded-full !bg-black/[0.04] dark:!bg-[var(--color-warm-grey-700)] dark:!text-white/70 max-md:top-[calc(100%-12px)] max-md:transform-none lg:left-0 lg:hidden dark:lg:hover:bg-white/15">
            <ChevronLeft className="color-[var(--color-cool-grey-700)] size-6" />
          </CarouselPrevious>
          <CarouselNext className="right-4 size-12 rounded-full !bg-black/[0.04] dark:!bg-[var(--color-warm-grey-700)] dark:!text-white/70 max-md:top-[calc(100%-12px)] max-md:transform-none lg:right-0 lg:hidden dark:lg:hover:bg-white/15">
            <ChevronRight className="size-6" />
          </CarouselNext>
        </Carousel>
        <div className="mb-16 mt-8">
          <ul className="flex flex-row justify-center gap-2 text-center">
            {dataCarousel.map((_, index) => (
              <li
                key={uniqueId(_.title)}
                className={cn(
                  "block h-1 w-1 rounded-full bg-[var(--color-cool-grey-600)] dark:bg-[var(--color-cool-grey-700)] lg:hidden",
                  slideIndex === index && "bg-black dark:bg-white",
                  {
                    "md:hidden": index + 3 > dataCarousel.length
                  }
                )}
              />
            ))}
          </ul>
        </div>
        <div className="px-4 md:px-8">
          <div className="mb-12 text-base md:mb-16 md:text-2xl">
            Glo classes are taught by world-renowned, certified instructors who embody wellness and teach with an
            intimate understanding of yoga traditions and holistic well-being. They bring you years of experience,
            devotion, and thoughtfulness in every class.
          </div>
          <div className="text-left md:text-center">
            <Link href={`${LEGACY_WEBSITE_URL}/teachers`} target="_blank" className="inline-block">
              <Button className="!pr-8">Meet our 50+ teachers</Button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};
