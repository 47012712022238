import { FC } from "react";

import { getCurrentUser } from "@/services/user.service";
import { User } from "@/types";

import { ResponsiveContainer } from "../ResponsiveContainer";
import { StickyNavBarContainer } from "./StickyNavBarContainer";
import { NavBarContent } from "./NavBarContent";

export const NavBar: FC = async () => {
  const user: User | null = await getCurrentUser()
    .then((res) => res)
    .catch(() => null);

  return (
    <StickyNavBarContainer user={user}>
      <ResponsiveContainer>
        <nav
          className={`flex flex-row items-center justify-between p-4 md:justify-center lg:py-4 ${user ? "md:px-2" : "md:py-4"}`}
        >
          <div className="relative mx-auto flex h-12 w-full flex-row items-center justify-between">
            <NavBarContent user={user} />
          </div>
        </nav>
      </ResponsiveContainer>
    </StickyNavBarContainer>
  );
};
