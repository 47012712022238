import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";

const buttonVariants = cva("button-base", {
  variants: {
    variant: {
      primary: "button-primary",
      secondary: "button-secondary",
      tertiary: "button-tertiary",
      borderless: "button-borderless",
      outline: "button-outline"
    },
    size: {
      md: "button-medium",
      sm: "button-small",
      lg: "button-large",
      compact: "button-compact",
      icon: "size-10 rounded-full"
    }
  },
  defaultVariants: {
    variant: "primary",
    size: "md"
  }
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, children, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";

    return (
      <Comp className={buttonVariants({ variant, size, className })} ref={ref} {...props}>
        {children}
      </Comp>
    );
  }
);
Button.displayName = "Button";

export { Button, buttonVariants };
