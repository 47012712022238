import { FC } from "react";

import { FeaturePromoHeader, FeaturePromoHeaderProps } from "./FeaturePromoHeader";
import { FeaturePromoContent, FeaturePromoContentProps } from "./FeaturePromoContent";

export interface FeaturePromoProps {
  headerProps: FeaturePromoHeaderProps;
  contentProps: FeaturePromoContentProps;
}

export const FeaturePromo: FC<FeaturePromoProps> = ({ headerProps, contentProps }) => {
  return (
    <>
      <FeaturePromoHeader {...headerProps} />
      <FeaturePromoContent {...contentProps} />
    </>
  );
};

