import { Level, LoggerOptions } from 'pino';

import { applicationSettings } from '@/constants';

const { LOG_LEVEL } = applicationSettings

export const loggerConfig: LoggerOptions = {
    level: LOG_LEVEL as Level,
    transport: {
        target: 'pino-pretty',
        options: {
            colorize: true,
            translateTime: true,
            ignore: 'pid,hostname',
        },
    }
};