"use client";

import { createContext, PropsWithChildren, useCallback, useContext, useEffect, useState } from "react";
import { StreamChat } from "stream-chat";

import { getStreamToken } from "@/services/user.service";
import { CoachingStatus, User } from "@/types";
import { applicationSettings } from "@/constants";
import { isRequireMemberShip } from "@/utils/user.utils";

type Props = PropsWithChildren<{
  user: User;
}>;

type ChatContext = {
  isLoading: boolean | undefined;
  isError: boolean | undefined;
};

const { NonParticipantNoSignup, SignedUpIntakePending, IntakeDoneCoachPending, FormerParticipant } = CoachingStatus;

const ChatCtx = createContext<ChatContext>({} as ChatContext);

export const chatClient = StreamChat.getInstance(applicationSettings.STREAM_API_KEY);

export function ChatContextProvider({ user, children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isError, setIsError] = useState<boolean>();
  const { coachingStatus, id, status } = user || {};

  const init = useCallback(async () => {
    setIsLoading(true);
    try {
      const streamToken = await getStreamToken(id);
      if (!streamToken) return;
      const { token } = streamToken;
      if (chatClient && !chatClient.user) {
        await chatClient.connectUser({ id }, token);
      }
    } catch (_) {
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  }, [id]);

  useEffect(() => {
    if (
      isRequireMemberShip({ coachingStatus, status } as User) ||
      [NonParticipantNoSignup, SignedUpIntakePending, IntakeDoneCoachPending, FormerParticipant].includes(
        coachingStatus
      )
    ) {
      return;
    }
    init();
  }, [id, coachingStatus, status, init]);

  useEffect(() => {
    return () => {
      if (chatClient.user) chatClient.disconnectUser();
    };
  }, []);

  return (
    <ChatCtx.Provider
      value={{
        isLoading,
        isError
      }}
    >
      {children}
    </ChatCtx.Provider>
  );
}

export function useChatContext() {
  return useContext(ChatCtx);
}
