"use client";

import { Chevron } from "@/ui/components/svgs";
import { cn } from "@/ui/utils";

export interface PagingPillButtonProps {
  onNext: () => void;
  onPrevious: () => void;
  label: string;
  isNextEnabled?: boolean;
  isPreviousEnabled?: boolean;
}

export function PagingPillButton({
  onNext,
  onPrevious,
  label,
  isNextEnabled,
  isPreviousEnabled
}: PagingPillButtonProps) {
  const sharedClasses = "bg-surface-action-tertiary border border-light";
  return (
    <div className="paging-pill-button inline-flex h-8 w-auto flex-row items-stretch overflow-hidden md:mx-0 md:h-10 md:w-fit">
      <button
        onClick={onPrevious}
        className={cn("rounded-l-[24px] pl-xs pr-xxxs md:pl-xxxs md:pr-4xs", sharedClasses)}
        disabled={!isPreviousEnabled}
        aria-label="Previous week"
      >
        <div className="size-[24px]">
          <Chevron
            orientation="left"
            className={cn({
              "fill-secondary": isPreviousEnabled,
              "cursor-not-allowed fill-disabled-high-contrast": !isPreviousEnabled
            })}
          />
        </div>
      </button>
      <div
        className={cn("flex w-full items-center justify-center border-x-0 px-[16px] font-bold md:w-fit", sharedClasses)}
      >
        {label}
      </div>
      <button
        onClick={onNext}
        className={cn("rounded-r-[24px] pl-xxxs pr-xs md:pl-4xs md:pr-xxxs", sharedClasses)}
        disabled={!isNextEnabled}
        aria-label="Next week"
      >
        <div className="size-[24px]">
          <Chevron
            orientation="right"
            className={cn({
              "fill-secondary": isNextEnabled,
              "cursor-not-allowed fill-disabled-high-contrast": !isNextEnabled
            })}
          />
        </div>
      </button>
    </div>
  );
}
