"use client";

import * as LabelPrimitive from "@radix-ui/react-label";
import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "@/ui/utils";

const labelVariants = cva("peer-disabled:cursor-not-allowed peer-disabled:opacity-70", {
  variants: {
    size: {
      h6: "text-h6",
      h4: "text-h4",
      sm: "text-sm",
      body: "body",
      caption: "text-caption"
    },
    color: {
      primary: "text-primary",
      secondary: "text-secondary",
      tertiary: "text-tertiary",
      error: "text-error"
    }
  },
  defaultVariants: {
    size: "body",
    color: "primary"
  }
});

export interface LabelProps
  extends Omit<React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>, "color">,
    VariantProps<typeof labelVariants> {}

const Label = React.forwardRef<React.ElementRef<typeof LabelPrimitive.Root>, LabelProps>(
  ({ className, size, color, ...props }, ref) => {
    return <LabelPrimitive.Root ref={ref} className={cn(labelVariants({ size, color, className }))} {...props} />;
  }
);
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
