import { FC, ReactElement } from "react";

import { cn } from "@/ui/utils";

export interface VideoPlayerOverlayProps {
  children: ReactElement;
  image?: string;
  onClick: () => void;
  overlayClassName?: string;
}

export const VideoPlayerOverlay: FC<VideoPlayerOverlayProps> = ({ children, image, onClick, overlayClassName }) => {
  return (
    <div className="absolute left-0 top-0 z-20 size-full" onClick={onClick}>
      <div className={cn("absolute left-0 top-0 z-30 size-full bg-gray-100 opacity-0", overlayClassName)} />

      <div className={cn("absolute left-0 top-0 z-40 size-full", overlayClassName)}>{children}</div>

      {image && (
        <div
          className={cn("absolute left-0 top-0 z-50 size-full bg-cover bg-no-repeat", overlayClassName)}
          style={{
            backgroundImage: `url(${image})`
          }}
        />
      )}
    </div>
  );
};
