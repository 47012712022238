"use client";
import React, { useEffect } from "react";
import useEmblaCarousel from "embla-carousel-react";
import { uniqueId } from "lodash-es";
import Image from "next/image";
import Link from "next/link";
import Autoplay from "embla-carousel-autoplay";

import { applicationSettings } from "@/constants";
import { Button } from "@/ui/shadcn";
import { cn } from "@/ui/utils";
import { useDotButton } from "@/ui/shadcn/EmblaCarouselDotButton";
import { NextButton, PrevButton, usePrevNextButtons } from "@/ui/shadcn/EmblaCarouselArrowButtons";
const { LEGACY_WEBSITE_URL } = applicationSettings;
const dataCarousel = [
  {
    title: "I love Glo!",
    description:
      "Glo is amazing! I have a yoga studio membership and I will not renew it and just stick to Glo. I’m so glad I found this app. I’ve been enjoying the lectures, yoga classes, mindfulness. I’ve tried yoga international and alo moves but Glo is different. It has more variety, more yoga classes that I like. I find myself finishing all the classes I start vs in alo and YI where I found myself changing mid-classes and can’t find classes that I like. The lectures by Annie Carpenter are gold. I like a lot of the yoga teachers already and I haven’t even tried all of them yet! I am very excited about this and how I can use it at home, when I go camping/ on vacation."
  },
  {
    title: "Best yoga app ever! Teachers are renowned in the industry and there's a class for everyone",
    description:
      "I have used this app for over 7 years. I absolutely love it! It offers many options, varied exercises, and targeted approach. I have used many apps - this one is by far the best of flexibility to choose exactly what you need when you need it. The instructors are renowned in their field, amongst the best in the industry. Highly recommend."
  },
  {
    title: "Best yoga instructors EVER",
    description:
      "I've been practicing yoga for over 15 years and am also a certified yoga instructor (though I don't teach often anymore). Of all the yoga apps out there, Glo has the absolutely most knowledgeable and dynamic instructors. I love that they have plenty of 30 minute practices as I mainly have a home practice and not a lot of time. It's been the best way to keep growing and improving my practice. Would 100% recommend to anyone who really wants to develop a home practice."
  }
];

export function GloReviews() {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay({ playOnInit: true, delay: 5000 })]);
  const { selectedIndex } = useDotButton(emblaApi);

  const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    emblaApi.on("select", () => {
      if (!autoplay.isPlaying()) {
        autoplay.play();
      }
    });
  }, [emblaApi]);

  return (
    <section id="glo-reviews" className="bg-[var(--color-primary-glo-black)] py-16 text-white md:py-[120px]">
      <h3 className="mx-auto mb-16 max-w-screen-sm px-4 text-center text-[32px] font-semibold leading-tight max-md:px-4 md:px-4 md:text-[40px] lg:mb-[72px] xl:text-5xl">
        See why over 2,000,000 have used Glo since 2008
      </h3>
      <div className="relative">
        <div ref={emblaRef} className="overflow-hidden">
          <div className="flex">
            {dataCarousel.map((item, index) => (
              <div className="flex-[0_0_100%]" key={index}>
                <div className="responsive-container px-4 lg:container md:px-8">
                  <div className="mb-6 flex justify-start md:justify-center md:px-4">
                    <Image
                      src="/v2/images/landing/reviews/stars.svg"
                      alt="5 Star"
                      width={155}
                      height={24}
                      loading="lazy"
                    />
                  </div>
                  <h4 className="mx-auto mb-10 w-auto text-left text-2xl font-medium !leading-tight md:px-20 md:text-center lg:text-[32px] xl:mb-12">
                    {item.title}
                  </h4>
                  <div className="text-left text-sm font-medium !leading-[144%] md:px-20 md:text-center md:text-xl xl:text-[32px]">
                    {item.description}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="mt-12 md:mt-[72px]">
          <ul className="flex flex-row justify-center gap-2 text-center">
            {dataCarousel.map((_, index) => (
              <li
                key={uniqueId(_.title)}
                className={cn(
                  "block h-1 w-1 rounded-full bg-[var(--color-warm-grey-600)]",
                  selectedIndex === index && "bg-white"
                )}
              />
            ))}
          </ul>
        </div>
        <div className="flex px-4 lg:container max-md:mt-6 max-md:justify-center max-md:gap-6 md:absolute md:left-1/2 md:top-1/2 md:w-full md:-translate-x-1/2 md:px-8">
          <PrevButton onClick={onPrevButtonClick} className="-bottom-5 left-10 md:absolute md:bottom-auto md:top-1/2" />
          <NextButton
            onClick={onNextButtonClick}
            className="-bottom-5 right-10 md:absolute md:bottom-auto md:top-1/2"
          />
        </div>
      </div>
      <div className="mt-16 text-center text-[32px] font-medium">
        <Link
          href={`${LEGACY_WEBSITE_URL}/register?adjust_referrer=adjust_reftag%3DcJRWj6S0YjD4G&utm_source=beginner-yoga-courses-and-classes`}
          target="_blank"
        >
          <Button variant="tertiary" className="border-white !pr-8 text-lg dark:bg-white dark:text-black">
            Join the Glo family
          </Button>
        </Link>
      </div>
    </section>
  );
}
