"use client";

import { logApmError } from "@/core/infrastructure/services/datadog/apm";
import { initDatadogRum, logRumError } from "@/core/infrastructure/services/datadog/rum";

initDatadogRum();

export function MonitorInit() {
  // Render nothing - this component is only included so that the init code above will run client-side
  return null;
}

class Monitor {
  error(message: string) {
    const isBrowser = typeof window !== "undefined";
    if (isBrowser) logRumError(message);
    else logApmError(message);
  }
}

export const monitor = new Monitor();

export function logToDatadog(...messages: unknown[]) {
  if (messages?.length === 0) {
    return;
  }

  // Log to Datadog Real User Monitoring
  const message = messages
    .map((partial: unknown) => {
      if (partial instanceof Error) {
        return partial.message;
      }

      if (partial?.toString) {
        return partial.toString();
      }

      return String(partial);
    })
    .join("\n");

  monitor.error(message);
}

