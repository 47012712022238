"use client";
import Image from "next/image";
import Link from "next/link";

import { PlayIcon } from "@/ui/components/svgs";
import { applicationSettings } from "@/constants";
const { LEGACY_WEBSITE_URL } = applicationSettings;

const imgPosterVideo = {
  desktop: "/v2/images/landing/video/poster-video.webp",
  mobile: "/v2/images/landing/video/poster-video-mobile.webp"
};
import "./VideoGuide.scss";

export const VideoGuide = () => {
  return (
    <section className="video-guide">
      <div className="relative">
        <Link href={`${LEGACY_WEBSITE_URL}/class/9329`} target="_blank">
          <div className="absolute top-0 z-10 max-w-[294px] px-4 pt-6 text-base font-medium text-black md:left-auto md:right-[34px] md:top-[34px] md:px-0 md:pt-0 md:text-xl xl:right-16 xl:top-16 xl:max-w-[352px] xl:text-2xl">
            Get started on Glo with this quick beginner yoga class; it&apos;s a member favorite!
          </div>
          <div className="absolute inset-0 m-auto flex size-[64px] items-center justify-center rounded-full border border-white bg-surface-smoked-medium pl-[5px] dark:bg-[var(--surface-smoked-medium-overimage)]">
            <div className="size-[34px]">
              <PlayIcon />
            </div>
          </div>
          <div className="relative">
            <picture>
              <source media="(max-width: 640px)" srcSet={imgPosterVideo.mobile} type="image/webp" />
              <Image
                src={imgPosterVideo.desktop}
                alt="Video Introduction"
                width={1200}
                height={1200}
                className="size-full"
              />
            </picture>
            <div className="linear-gradient-video-guide md:hidden"></div>
          </div>
          <div className="bottom-[32px] left-[32px] z-10 px-4 pb-6 pt-4 text-black max-md:bg-white max-sm:-translate-y-px md:absolute md:px-0 md:pb-0 xl:bottom-16 xl:left-16">
            <div className="mb-4 text-base font-medium md:text-xl lg:mb-6 lg:text-2xl">Warming The Body</div>
            <div className="flex items-center">
              <Image
                className="size-[30px] md:size-12"
                src="/v2/images/landing/video/profile.webp"
                alt="Profile"
                width={48}
                height={48}
                loading="lazy"
              />
              <div className="flex flex-col justify-center pl-3">
                <span className="mb-2.5 text-xs font-medium !leading-none md:text-sm lg:mb-3 lg:text-base">
                  Hatha with Elena Brower
                </span>
                <span className="text-xs !leading-none md:text-sm lg:text-base">Level 1-2 — 15 mins</span>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};
