import { FC, useState, ChangeEvent } from "react";
import { SpeakerOffIcon, SpeakerQuietIcon, SpeakerModerateIcon, SpeakerLoudIcon } from "@radix-ui/react-icons";

import { cn } from "@/ui/utils";

import classes from "./SoundVolume.module.scss";

const MIN_SOUND_VOLUME_VALUE = 0;

const iconStyles = {
  color: "white",
  height: 18,
  width: 22
};

interface VolumeIconProps {
  isMuted: boolean;
  volume: number;
}

// Player volume icon
const VolumeIcon: FC<VolumeIconProps> = ({ isMuted, volume }) => {
  if (!volume || isMuted) {
    return <SpeakerOffIcon style={iconStyles} />;
  } else if (volume < 33) {
    return <SpeakerQuietIcon style={iconStyles} />;
  } else if (volume < 66) {
    return <SpeakerModerateIcon style={iconStyles} />;
  } else {
    return <SpeakerLoudIcon style={iconStyles} />;
  }
};

interface SoundVolumeProps {
  soundVolumeId: string;
  onChange?: (value: number) => void;
}

export const SoundVolume: FC<SoundVolumeProps> = ({ onChange, soundVolumeId }) => {
  const [showSlider, setShowSlider] = useState(false);
  const [volume, setVolume] = useState(50);
  const [beforeMuteValue, setBeforeMuteValue] = useState(volume);
  const [isMuted, setIsMuted] = useState(false);

  const displaySlider = () => setShowSlider(true);

  const hideSlider = () => setShowSlider(false);

  const updateVolumeValue = (newValue: number) => {
    setVolume(newValue);
    if (onChange) onChange(newValue);
  };

  const handleButtonClick = () => {
    if (isMuted) {
      updateVolumeValue(beforeMuteValue);
    } else {
      setBeforeMuteValue(volume);
      updateVolumeValue(MIN_SOUND_VOLUME_VALUE);
    }

    setIsMuted(!isMuted);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);

    updateVolumeValue(newValue);
    setIsMuted(newValue === MIN_SOUND_VOLUME_VALUE);
  };

  return (
    <div
      role="group"
      id={soundVolumeId}
      tabIndex={0}
      className={classes["sound-volume"]}
      onFocus={displaySlider}
      onMouseLeave={hideSlider}
    >
      <div
        aria-label="Mute"
        className={classes["sound-volume__volume-btn"]}
        onClick={handleButtonClick}
        onMouseEnter={displaySlider}
      >
        <VolumeIcon isMuted={isMuted} volume={volume} />
      </div>

      <div
        className={cn(classes["sound-volume__container-absolute"], {
          [classes["sound-volume__show"]]: showSlider
        })}
        onMouseEnter={() => setShowSlider(true)}
        onMouseLeave={() => setShowSlider(false)}
        tabIndex={0}
      >
        <div className={classes["sound-volume__general-volume"]}>
          <div className={classes["sound-volume__input-container"]}>
            <input
              className={classes["video-volume-input"]}
              id="video-volume-input"
              value={volume}
              type="range"
              step={1}
              aria-valuemin={1}
              aria-valuemax={100}
              aria-label="Video Balance"
              onChange={handleInputChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
