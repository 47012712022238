"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import { Avatar, AvatarFallback, AvatarImage } from "@/ui/shadcn";
import { User } from "@/types/User";

const getUserInitials = (firstName: string, lastName: string) => {
  if (firstName || lastName) {
    return [firstName, lastName].filter(Boolean).map((value) => value.charAt(0).toUpperCase());
  }

  return "ME";
};
interface UserAvatarProps {
  user: User | null;
}
export const UserAvatar = ({ user }: UserAvatarProps) => {
  const pathname = usePathname();
  const isGiftPage = pathname.includes("/v2/gift");

  if (!user) {
    return null;
  }

  const { firstName, lastName, profileImage } = user;

  const initials = getUserInitials(firstName, lastName);

  return (
    !isGiftPage && (
      <Link href="/v2/glo-guides/account">
        <Avatar>
          <AvatarImage src={profileImage} alt="user avatar" />
          <AvatarFallback className="bg-surface-hilite-aqua font-amerigo text-username">{initials}</AvatarFallback>
        </Avatar>
      </Link>
    )
  );
};
