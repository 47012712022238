"use client";
import Link from "next/link";
import { usePathname } from "next/navigation";

import { useToggle } from "@/lib/hooks/useToggle";
import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon
} from "@/ui/shadcn";
import { User } from "@/types";
import { useAuth } from "@/context/AuthContext";
import { cn } from "@/ui/utils";

import { MobileMenuOption } from "./MobileMenuOption";
import { FOR_YOU_URL, ITEMS_MOBILE, ITEMS_WITHOUT_LOGIN } from "./Navbar.constants";
import { LogoGlo } from "./LogoGlo";
import { MsgNotificationBadge } from "./MsgNotificationBadge";
import { isPlanV2Page } from "./utils";

interface MobileMenuProps {
  user: User | null;
}

export function MobileMenu({ user }: MobileMenuProps) {
  const [open, toggle] = useToggle(false);
  const { isAuthenticated } = useAuth();
  const pathname = usePathname();
  const isGiftPage = pathname.includes("/v2/gift");
  const navItems = user || isAuthenticated ? (isGiftPage ? [] : ITEMS_MOBILE) : ITEMS_WITHOUT_LOGIN;

  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button size="icon" variant="borderless" className="sm:hidden" onClick={toggle}>
          <Icon name="menu" className="text-secondary" />
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent
        align="start"
        className="absolute -left-4 -top-16 z-[110] h-screen w-[300px] overflow-auto rounded-none bg-white"
      >
        {/* Header */}
        <div className="flex items-center justify-center border-b px-4 py-3">
          <Button size="icon" variant="borderless" className="absolute left-1" onClick={toggle}>
            <Icon name="close" className="text-[var(--color-primary-glo-black)]" />
          </Button>
          <LogoGlo />
        </div>
        {/* Menu Items */}
        <DropdownMenuGroup
          className={cn(
            isGiftPage &&
              "mx-auto flex h-[calc(100vh-85px)] max-h-[400px] min-h-[170px] max-w-[180px] flex-col justify-center"
          )}
        >
          {navItems.map((item, index) =>
            item.type === "option" ? (
              <MobileMenuOption
                key={item?.label}
                {...item}
                className={cn(
                  `${user || isAuthenticated ? "text-[var(--color-primary-glo-black)]" : "mb-8 h-[50px] justify-center rounded-[24px] border px-[24px] py-[12px] font-medium !text-[var(--color-primary-glo-black)] focus:text-[var(--color-primary-glo-black)]"}`,
                  isPlanV2Page(item.href) && "flex items-center gap-1"
                )}
              >
                {isPlanV2Page(item.href) && <MsgNotificationBadge user={user} />}
              </MobileMenuOption>
            ) : (
              <DropdownMenuSeparator key={item?.label ?? index} className="my-2 border-b" />
            )
          )}
          {isGiftPage && (
            <Link
              href={FOR_YOU_URL}
              className={cn(
                "inline-block whitespace-nowrap rounded-[24px] border px-[24px] py-[12px] text-center font-medium text-[var(--color-primary-glo-black)] transition-colors duration-300 ease-in-out hover:border-primary",
                user || isAuthenticated ? "bg-white" : "border-[var(--color-glo-yellow)] bg-[var(--color-glo-yellow)]"
              )}
            >
              {user || isAuthenticated ? "Enter Glo" : "Try free for 7 days"}
            </Link>
          )}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
