import { applicationSettings } from "@/constants";

import { MenuItem } from "./MobileMenuOption";

const { LEGACY_WEBSITE_URL, ENABLE_FOR_YOU } = applicationSettings;
export const FOR_YOU_URL = ENABLE_FOR_YOU ? "/v2/for-you" : `${LEGACY_WEBSITE_URL}/for-you`;

export const ITEMS: MenuItem[] = [
  { type: "option", label: "For you", href: FOR_YOU_URL },
  { type: "option", label: "Live classes", href: `${LEGACY_WEBSITE_URL}/live` },
  { type: "option", label: "Your Plan", href: "/v2/plan" },
  { type: "option", label: "Search", href: `${LEGACY_WEBSITE_URL}/search` }
];

export const ITEMS_WITHOUT_LOGIN: MenuItem[] = [
  { type: "option", label: "Login", href: `${LEGACY_WEBSITE_URL}/login` }
];

export const ITEMS_MOBILE: MenuItem[] = [
  ...ITEMS,
  { type: "option", label: "My Library", href: `${LEGACY_WEBSITE_URL}/library` },
  { type: "option", label: "My Practice", href: `${LEGACY_WEBSITE_URL}/my-practice` },
  { type: "divider" },
  { type: "option", label: "Settings", href: `${LEGACY_WEBSITE_URL}/settings` }
];

export const ITEMS_LANDING_PAGE: MenuItem[] = [
  { type: "option", label: "Styles", anchorId: "styles", href: "#" },
  { type: "option", label: "Schedule", anchorId: "schedule", href: "#" },
  { type: "option", label: "Teachers", anchorId: "teachers", href: "#" },
  { type: "option", label: "Intentional Living", anchorId: "intentional-living", href: "#" },
  { type: "divider" },
  { type: "option", label: "Login", href: `${LEGACY_WEBSITE_URL}/login` }
];
