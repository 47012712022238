import { FC, isValidElement, ReactElement } from "react";
import Link from "next/link";
import Image from "next/image";
import { isEmpty } from "lodash-es";

import { ResponsiveContainer } from "@/ui/components";
import { Button } from "@/ui/shadcn";

export interface FeaturePromoContentProps {
  promoImageUrls?: {
    sm: string;
    md: string;
    lg: string;
  };
  heading: string;
  subtitle?: string;
  content: string[] | ReactElement;
  notes?: string[];
  buttons?: Array<{
    label: string | ReactElement;
    variant: "primary" | "secondary" | "tertiary" | "borderless";
    href: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  }>;
}

export const FeaturePromoContent: FC<FeaturePromoContentProps> = ({
  promoImageUrls,
  heading,
  subtitle,
  content,
  notes,
  buttons
}) => {
  return (
    <ResponsiveContainer>
      <div className="block flex-row md:flex">
        {/* Promotion background picture */}
        {promoImageUrls && (
          <div className="flex-initial">
            <div className="size-full md:relative md:min-h-[400px] md:w-[416px]">
              {promoImageUrls.sm && (
                <Image
                  src={promoImageUrls.sm}
                  alt={heading}
                  className="w-full object-cover md:hidden"
                  width={500}
                  height={500}
                />
              )}

              {promoImageUrls.md && (
                <Image src={promoImageUrls.md} alt={heading} className="hidden object-cover md:block lg:hidden" fill />
              )}

              {promoImageUrls.lg && (
                <Image src={promoImageUrls.lg} alt={heading} className="hidden object-cover lg:block" fill />
              )}
            </div>
          </div>
        )}

        {/* Promotion main content */}
        <div className="flex-auto">
          <div className="flex flex-col gap-mobile-contract-S px-spacing-grid-h-padding py-mobile-contract-L">
            <div className="flex flex-col gap-2">
              <h5 className="header/h5 text-primary">{heading}</h5>
              {subtitle && <span>{subtitle}</span>}
            </div>
            <div className="flex flex-col gap-xxxl">
              {isValidElement(content) ? (
                content
              ) : (
                <>
                  {Array.isArray(content) && (
                    <div className="flex flex-col gap-6">
                      {content.map((text: string, index: number) => (
                        <p key={index} className="text-body text-primary">
                          {text}
                        </p>
                      ))}
                    </div>
                  )}
                </>
              )}

              <>
                {!isEmpty(notes) && (
                  <div className="rounded-4xs border border-light bg-surface-default-primary p-xs">
                    {notes?.map((note, index) => <label key={index}>{note}</label>)}
                  </div>
                )}
              </>

              <>
                {!isEmpty(buttons) && (
                  <div className="flex flex-col items-center justify-center gap-2 md:flex-row md:justify-start">
                    <>
                      {buttons?.map(({ label, variant, href, onClick }, index) => (
                        <Link key={index} href={href} className="w-full md:w-auto">
                          <Button
                            size="md"
                            variant={variant}
                            className="label w-full"
                            onClick={
                              onClick !== undefined
                                ? (e: React.MouseEvent<HTMLButtonElement>) =>
                                    onClick(e as unknown as React.MouseEvent<HTMLAnchorElement>)
                                : undefined
                            }
                          >
                            {label}
                          </Button>
                        </Link>
                      ))}
                    </>
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
    </ResponsiveContainer>
  );
};
