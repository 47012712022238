import { PropsWithChildren, useCallback } from 'react';

import { ToggleGroup, ToggleGroupItem } from '@/ui/shadcn';

interface SegmentedBarProps extends PropsWithChildren {
  options?: { value: string; label: string }[];
  onSelect: (value: string) => void;
  initialValue?: string;
}

const defaultOptions: SegmentedBarProps['options'] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
];

export const SegmentedBar = ({ options = defaultOptions, onSelect, initialValue = '' }: SegmentedBarProps) => {
  const handleOnItemClick = useCallback(
    (value: string) => () => onSelect(value),
    [onSelect]
  );

  return (
    <ToggleGroup type="single" className="w-full flex flex-row gap-0" defaultValue={initialValue}>
      {options.map((o) => (
        <ToggleGroupItem
          key={o.value}
          value={o.value}
          className="font-bold text-center grow border border-solid border-medium border-r-0 last:border-r rounded-none first:rounded-l-full last:rounded-r-full text-secondary text-label-small h-[48px] sm:h-xxl hover:text-secondary hover:bg-surface-action-tertiary-hover data-[state=on]:bg-surface-action-selected data-[state=on]:text-inverse hover:data-[state=on]:bg-surface-action-selected-hover data-[state=on]:border-selected hover:data-[state=on]:border-selected-secondary"
          onClick={handleOnItemClick(o.value)}
        >
          {o.label}
        </ToggleGroupItem>
      ))}
    </ToggleGroup>
  );
};
