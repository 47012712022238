import { useMemo } from "react";
import { AnalyticsBrowser } from "@segment/analytics-next";

import { applicationSettings } from "@/constants";

const { SEGMENT_WRITE_KEY } = applicationSettings;

const useSegmentAnalytics = () => {
  const analytics = useMemo(() => {
    if (typeof window === "undefined") {
      return null;
    }

    return AnalyticsBrowser.load({
      writeKey: SEGMENT_WRITE_KEY
    });
  }, []);

  return analytics;
};

export default useSegmentAnalytics;
