import React from 'react';
import Image from 'next/image';

import { cn } from '@/ui/utils';

interface IconImageProps {
  name?: string;
  src?: string;
  alt: string;
  className?: string;
  width?: number | 'auto';
  height?: number | 'auto';
  style?: React.CSSProperties;
}

const iconMap: Record<string, string> = {
  logo: '/assets/logo.svg',
  // Add other mappings here
};

export const IconImage: React.FC<IconImageProps> = ({
  name,
  src,
  alt,
  className,
  width = 24,
  height = 24,
  style,
  ...props
}) => {
  const imageUrl = name ? iconMap[name] : src;

  if (!imageUrl) {
    return null;
  }

  return (
    <Image
      src={imageUrl}
      alt={alt ?? name ?? imageUrl}
      className={cn('text-primary', className)}
      width={width === 'auto' ? undefined : width}
      height={height === 'auto' ? undefined : height}
      style={style}
      {...props}
    />
  );
};

export default IconImage;
