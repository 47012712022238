"use client";
import Link from "next/link";

import {
  Button,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  Icon
} from "@/ui/shadcn";
import { cn } from "@/ui/utils";
import { applicationSettings } from "@/constants";
import { Logo } from "@/ui/assets/Logo";

import { MobileMenuOption } from "./MobileMenuOption";
import { ITEMS_LANDING_PAGE } from "./Navbar.constants";

const { LEGACY_WEBSITE_URL } = applicationSettings;

interface MobileMenuHomeProps {
  open: boolean;
  toggle: () => void;
  scrollToElement: (id?: string) => void;
}
export const MobileMenuHome: React.FC<MobileMenuHomeProps> = ({ open, toggle, scrollToElement }) => {
  return (
    <DropdownMenu open={open}>
      <DropdownMenuTrigger asChild>
        <Button
          size="icon"
          variant="borderless"
          className="text-black outline-none lg:hidden"
          aria-label="Menu"
          rel="noopener noreferrer"
          onClick={toggle}
        >
          <Icon name="menu" className="text-secondary" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuPortal>
        <>
          <DropdownMenuContent
            className={cn(
              "!duration-400 absolute -left-[calc(266px+100vw)] -top-16 z-[110] h-screen w-[300px] translate-x-0 overflow-auto rounded-none bg-white transition-transform ease-in-out md:-left-[calc(241px+100vw)]",
              open && "translate-x-[300px]"
            )}
          >
            {/* Header */}
            <div className="flex items-center justify-center border-b px-4 py-3">
              <Button size="icon" variant="borderless" className="absolute left-1" onClick={toggle}>
                <Icon name="close" className="text-black" />
              </Button>
              <Link href={LEGACY_WEBSITE_URL}>
                <Logo className="text-black" />
              </Link>
            </div>
            {/* Menu Items */}
            <DropdownMenuGroup>
              {ITEMS_LANDING_PAGE.map((item, index) =>
                item.type === "option" ? (
                  !item.anchorId ? (
                    <MobileMenuOption
                      key={item?.label}
                      label={item.label}
                      href={item.href}
                      className="!h-12 cursor-pointer font-medium !text-black"
                    />
                  ) : (
                    <Link
                      key={item?.label}
                      href={item.href ?? ""}
                      className={cn(
                        "my-2 flex h-12 w-full cursor-pointer items-center px-xs py-0 text-sm font-medium text-black"
                      )}
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToElement(item.anchorId);
                        toggle();
                      }}
                    >
                      {item.label}
                    </Link>
                  )
                ) : (
                  <DropdownMenuSeparator key={item?.label ?? index} className="my-2 border-b" />
                )
              )}
              <Link href={`${LEGACY_WEBSITE_URL}/register`} className="px-4">
                <Button className="whitespace-nowrap !bg-black !px-8 !text-white">
                  Try free<span className="max-sm:hidden"> for 7 days</span>
                </Button>
              </Link>
            </DropdownMenuGroup>
          </DropdownMenuContent>
          <div
            className={cn(
              "duration-400 pointer-events-auto fixed left-0 top-0 z-0 h-dvh w-dvw bg-black/50 opacity-0 transition-opacity ease-in-out",
              open && "opacity-1 z-[110]"
            )}
            onClick={toggle}
          ></div>
        </>
      </DropdownMenuPortal>
    </DropdownMenu>
  );
};
