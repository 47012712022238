import * as React from "react";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/ui/utils";

const badgeVariants = cva("flex items-center bg-surface-default-badge text-white justify-center truncate ", {
  variants: {
    variant: {
      "pill-small": "p-2 py-1 inline-flex !text-label-smallest rounded-xxs size-sm",
      "pill-medium": "px-xxs py-xxxs inline-flex text-label rounded-xxs h-base size-md",
      "pill-large": "px-xxs py-xxxs inline-flex text-label-large rounded-xxs h-xl size-lg",
      "disc-small": "px-xxxs py-0 flex text-label-large size-sm",
      "disc-medium": "px-xxxs py-0 flex text-label size-lg",
      "disc-large": "px-xxxs py-0 flex text-label-large size-xl",
      "rectangle-smallest": "bg-surface-smoked-medium-overimage text-xxs font-xs p-1 rounded",
      "rectangle-small": "bg-surface-smoked-medium-overimage text-xxs font-xxs px-xxxs py-4xs rounded",
      "rectangle-medium": "bg-surface-smoked-medium-overimage text-xs font-small px-xxxs py-4xs rounded",
      "rectangle-large": "bg-surface-smoked-medium-overimage text-sm font-medium px-xxxs py-4xs rounded"
    }
  },
  defaultVariants: {
    variant: "pill-medium"
  }
});

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(badgeVariants({ variant }), className)} {...props} />;
}

export { Badge, badgeVariants };
