"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";

import {
  navigationMenuTriggerStyle,
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList
} from "@/ui/shadcn/NavigationMenu";
import { cn } from "@/ui/utils";
import { User } from "@/types";
import { useAuth } from "@/context/AuthContext";

import { FOR_YOU_URL, ITEMS, ITEMS_WITHOUT_LOGIN } from "./Navbar.constants";
import { MsgNotificationBadge } from "./MsgNotificationBadge";
import { isPlanV2Page } from "./utils";

interface DesktopMenuProps {
  user: User | null;
}

export const DesktopMenu = ({ user }: DesktopMenuProps) => {
  const { isAuthenticated } = useAuth();
  const pathname = usePathname();
  const isGiftPage = pathname.includes("/v2/gift");
  const navItems = user || isAuthenticated ? (isGiftPage ? [] : ITEMS) : ITEMS_WITHOUT_LOGIN;

  return (
    <NavigationMenu className="hidden self-center sm:inline-flex">
      <NavigationMenuList>
        {navItems.map((item) => {
          const isPlanPage = isPlanV2Page(item.href);
          return (
            <NavigationMenuItem key={item.label}>
              <Link href={item.href ?? "/"} passHref legacyBehavior>
                <NavigationMenuLink
                  active={pathname?.endsWith(item.href ?? "")}
                  className={cn(
                    navigationMenuTriggerStyle(),
                    !user && "font-medium",
                    isPlanPage && "flex items-center gap-1"
                  )}
                >
                  {item.label}
                  {isPlanPage && <MsgNotificationBadge user={user} />}
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          );
        })}
        {isGiftPage && (
          <Link
            href={FOR_YOU_URL}
            className={cn(
              "inline-block whitespace-nowrap rounded-[24px] border px-[24px] py-[12px] text-center font-medium text-[var(--color-primary-glo-black)] transition-colors duration-300 ease-in-out hover:border-primary",
              user || isAuthenticated ? "bg-white" : "border-[var(--color-glo-yellow)] bg-[var(--color-glo-yellow)]"
            )}
          >
            {user || isAuthenticated ? "Enter Glo" : "Try free for 7 days"}
          </Link>
        )}
      </NavigationMenuList>
    </NavigationMenu>
  );
};
