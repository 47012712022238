import { CoachingStatus, User, UserStatus } from "@/types";

const ACTIVE_GLO_GUIDES_USER_STATUSES = [
  CoachingStatus.SignedUpIntakePending,
  CoachingStatus.IntakeDoneCoachPending,
  CoachingStatus.CoachAssignedPlanPending,
  CoachingStatus.ActiveParticipant
];

const { Guest, SuspendBeforeBillingCycle, SuspendAfterBillingCycle } = UserStatus;

export function isRequireMemberShip(user: User) {
  if (!user) {
    return true;
  }

  const { coachingStatus, status } = user;

  const isActiveGuidesUser = ACTIVE_GLO_GUIDES_USER_STATUSES.includes(coachingStatus);

  const isGuest = [Guest, SuspendBeforeBillingCycle].includes(status as UserStatus);
  const isSuspended = status === SuspendAfterBillingCycle;
  return isSuspended || (isGuest && !isActiveGuidesUser);
}
