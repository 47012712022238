import { cn } from "@/ui/utils"

interface ChevronProps {
  orientation: "left" | "right";
  className?: string;
}

export function Chevron({ orientation, className }: ChevronProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" className={cn("fill-primary", className)}>
      {orientation === "left" ? (
        <path d="M8.90186 12.0068C8.90186 12.1507 8.92952 12.2835 8.98486 12.4053C9.0402 12.527 9.12598 12.646 9.24219 12.7622L14.2144 17.6846C14.397 17.8672 14.6183 17.9585 14.8784 17.9585C15.1496 17.9585 15.3792 17.8672 15.5674 17.6846C15.7555 17.4964 15.8496 17.2695 15.8496 17.0039C15.8496 16.7383 15.7472 16.5031 15.5425 16.2983L11.1846 12.0068L15.5425 7.71533C15.7472 7.51058 15.8496 7.27539 15.8496 7.00977C15.8496 6.74414 15.7555 6.52002 15.5674 6.3374C15.3792 6.14925 15.1496 6.05518 14.8784 6.05518C14.6183 6.05518 14.397 6.14648 14.2144 6.3291L9.24219 11.2515C9.0153 11.4673 8.90186 11.7191 8.90186 12.0068Z"/>
      ) : (
        <path d="M15.8496 12.0068C15.8496 12.1507 15.8219 12.2835 15.7666 12.4053C15.7113 12.527 15.6255 12.646 15.5093 12.7622L10.5371 17.6846C10.3545 17.8672 10.1331 17.9585 9.87305 17.9585C9.60189 17.9585 9.37223 17.8672 9.18408 17.6846C8.99593 17.4964 8.90186 17.2695 8.90186 17.0039C8.90186 16.7383 9.00423 16.5031 9.20898 16.2983L13.5669 12.0068L9.20898 7.71533C9.00423 7.51058 8.90186 7.27539 8.90186 7.00977C8.90186 6.74414 8.99593 6.52002 9.18408 6.3374C9.37223 6.14925 9.60189 6.05518 9.87305 6.05518C10.1331 6.05518 10.3545 6.14648 10.5371 6.3291L15.5093 11.2515C15.7362 11.4673 15.8496 11.7191 15.8496 12.0068Z"/>
      )}
      
    </svg>
  )
}
