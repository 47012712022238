"use client";

import { usePathname } from "next/navigation";
import Link from "next/link";
import { PropsWithChildren } from "react";

import { DropdownMenuItem } from "@/ui/shadcn";
import { cn } from "@/ui/utils";

export interface MenuItem {
  label?: string;
  href?: string;
  type: "option" | "divider";
  className?: string;
  anchorId?: string;
}

type Props = PropsWithChildren<Omit<MenuItem, "type">>;
export const MobileMenuOption = ({ href, label, className, children }: Props) => {
  const pathname = usePathname();

  return (
    <DropdownMenuItem
      className={cn(
        "my-2 h-9 w-full px-xs py-0 font-normal text-secondary",
        pathname?.endsWith(href ?? "") ? "border-l-4 border-l-gray-900 font-medium dark:border-l-gray-50" : "",
        className
      )}
    >
      <Link href={href as string}>{label}</Link>
      {children}
    </DropdownMenuItem>
  );
};
