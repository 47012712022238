import { Audio, MediaType, Style, Teacher, Video } from ".";

export enum DailyClassType {
  DailyEnergy = "Daily Energy",
  DailyMindfulness = "Daily Mindfulness",
  DailyUnwind = "Daily Unwind"
}

export interface UserDailyClass {
  id: string;
  type: "userDailyClasses";
  title: string;
  description: string;
  shortDescription: string;
  duration: number;
  slug: string;
  createdAt: string;
  rotationDate: string;
  dailyClassType: DailyClassType;
  sortOrder: number;
  isCompleted: boolean;
  isLive: boolean;
  video?: Video;
  teachers: Teacher[];
  audio?: Audio;
  performanceScore: number;
  mediaType: MediaType;
  ratingsCount: number;
  ratingsAverage: number;
  style: Style;
  url: {
    hls: string;
  };
  trialClassSchedule: boolean;
  trialClassDay: unknown;
  dailyClassTagId: unknown;
  dailyClassRotationDate: string;
  // Computed fields
  progress: number | null;
}
