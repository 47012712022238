import type { LucideProps } from "lucide-react";
import React, { CSSProperties } from "react";
import { Calendar, ChevronRight, Menu, X } from "lucide-react";

import { cn } from "../utils";

const icons = {
  calendar: Calendar,
  "chevron-right": ChevronRight,
  menu: Menu,
  close: X
};

export type IconNames = keyof typeof icons;
export type IconProps = LucideProps & {
  name: IconNames;
  className?: string;
  onClick?: () => void;
  width?: number | "auto";
  height?: number | "auto";
  style?: CSSProperties;
};

export const Icon: React.FC<IconProps> = ({ name, className, onClick, width = 24, height = 24, style, ...props }) => {
  const IconComponent = icons[name];
  return (
    <IconComponent className={cn(className)} onClick={onClick} width={width} height={height} style={style} {...props} />
  );
};
