import Image from "next/image";

import { cn } from "@/ui/utils";

interface AudioClassThumbnailProps {
  imageUrl: string;
  imageAlt?: string;
  className?: string;
  large?: boolean;
}

export function AudioClassThumbnail({ imageUrl, imageAlt = "", className, large }: AudioClassThumbnailProps) {
  return (
    <div
      className={cn(
        "absolute inset-0 flex size-full items-center justify-center bg-surface-default-secondary",
        className
      )}
    >
      <div
        className={cn(
          "relative h-[45%] w-1/4 overflow-hidden rounded-full",
          large ? "shadow-audio-avatar-large" : "shadow-audio-avatar"
        )}
      >
        {imageUrl && (
          <Image
            src={imageUrl}
            className="size-full object-cover"
            alt={imageAlt}
            fill
            sizes="(max-width: 640px) 33vw, 10vw"
          />
        )}
      </div>
    </div>
  );
}
