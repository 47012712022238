import * as React from "react";

import { cn } from "../utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  variant?: "default" | "primary";
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, type, variant = "default", ...props }, ref) => {
    let classInput =
      "flex h-12 w-full rounded-xxxs border border-stone-300 bg-surface-default-secondary px-xs py-xxs text-sm text-primary ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-disabled-high-contrast focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50";
    if (variant === "primary") {
      classInput =
        "border-[var(--color-glo-gray-3)] inline-block w-full border-b bg-transparent pb-[16px] pt-[32px] text-[16px] outline-none transition duration-500 placeholder:text-[var(--surface-action-primary)] hover:border-black";
    }
    return <input type={type} className={cn(classInput, className)} ref={ref} {...props} />;
  }
);
Input.displayName = "Input";

export { Input };
