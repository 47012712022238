export enum CoachingStatus {
  NonParticipantNoSignup = "NonParticipant_NoSignup",
  SignedUpIntakePending = "SignedUp_IntakePending",
  IntakeDoneCoachPending = "IntakeDone_CoachPending",
  CoachAssignedPlanPending = "CoachAssigned_PlanPending",
  ActiveParticipant = "ActiveParticipant",
  FormerParticipant = "FormerParticipant"
}

export enum UserStatus {
  DeclinedPaid = "declined_paid",
  DeclinedTrial = "declined_trial",
  Free = "free",
  Guest = "guest",
  Hold = "hold",
  Paid = "paid_user",
  Suspend = "suspend",
  SuspendAfterBillingCycle = "suspend_after_billing_cycle",
  SuspendBeforeBillingCycle = "suspend_before_billing_cycle",
  Trial = "trial"
}

export interface User {
  id: string;
  type: "user";
  firstName: string;
  profileImage: string;
  dateLastGiftClassPrompt: unknown;
  lastName: string;
  email: string;
  registrationDate: string;
  coachingStatus: CoachingStatus;
  links: {
    self: string;
  };
  teacherId?: string | null;
  address: string | null;
  facebookId: string | null;
  stravaToken: string | null;
  status: string;
  emailVerificationStatus: string;
  locale: string;
  holdStartDate: string | null;
  paymentType: string;
  browserTheme: string;
  appleId: string | null;
  signUpType: string;
  videoMusicRatio: number;
  referralCode: string;
  currency: string;
  paymentCountry: string;
  holdEndDate: string | null;
  lifetime: boolean;
  coachId: string | null;
  onboardingSelectedClassId: string | null;
  phoneNumber: string | null;
  phoneNumberCountryDialCode: string | null;
  phoneNumberCountryCode: string | null;
  city: string | null;
  zip: string | null;
  state: string | null;
  country: string | null;
  timezone: string;
  paymentProcessorId: string;
  videoAccess: boolean;
  eligibleForNewSubscription: boolean;
  billingDate: string;
  daysUntilBillingDate: number;
  currentlyDiscounted: boolean;
  hasBeenDiscounted: boolean;
  optInMarketing: boolean;
  optInAnalytics: boolean;
  optInProgramsScheduledClass: boolean;
  optInProgramsWeeklyRecap: boolean;
  optInProgramsSkippedClass: boolean;
  optInMoment: boolean;
  optInClassReminder: boolean;
  optInLivestream: boolean;
  optInPractice: boolean;
  optInSms: boolean;
  optInGoals: boolean;
  flags: {
    dismissedAnnouncement7: boolean;
    seenMomentNotificationsRequestModal: boolean;
    seenNewUserWelcomeModal: boolean;
    hasSeenMusicFeature: boolean;
    seenLivestreamTooltip: boolean;
    dismissedBeginnersPath: boolean;
    seenReferralProgramAnnouncement: boolean;
    selfCareFeb2022PromoModal: boolean;
    womensDay2022PromoModal: boolean;
    seenWhatsNewModal: boolean;
    mentalHealth2022PromoModal: boolean;
  };
  policies: {
    termsAndConditions: {
      type: string;
      version: string;
      agreedAt: string | null;
      isOutdated: boolean;
    };
    privacyPolicy: {
      type: string;
      version: string;
      agreedAt: string | null;
      isOutdated: boolean;
    };
  };
  classSharesRemaining: number;
  wasReferred: boolean;
  coachingIntake: {
    userCoachIntakeId: number;
    userId: number;
    experience: string | null;
    numberOfTimesPerWeek: number;
    practiceDays: string[] | null;
    bio: string | null;
    active: boolean;
    status: string;
    intakeUrl: string | null;
    dateCreated: string;
    dateUpdated: string;
    details: {
      intakeInfo: Record<string, unknown>;
      memberOverview: Record<string, unknown>;
    };
    coachSubscriptionType: string;
    genderId: number;
    genderOption: string;
    birthDate: string;
    id: number;
  };
  subscriptionPlanId: string;
  hasCoachingAccess: boolean;
}

export type UserPayload = {
  userInfo: {
    agreedToTerms: boolean;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    locale: string;
    optInMarketing: boolean;
    timezone: string;
  };
  onboardingSelections: null;
};

export type CreateUserQuery = {
  isGiftedSubscriptionRecipient?: boolean;
};
