export enum SubscriptionStatus {
  Active = "active",
  Cancelled = "cancelled",
  Inactive = "inactive",
  Paused = "paused",
  Trialing = "trialing",
  Hold = "hold"
}

export interface CancellationReason {
  id: string;
  name: string;
}

export interface Link {
  self: string;
}

export interface SubscriptionPlan {
  type: string;
  id?: string;
  subscriptionGroup: number;
  provider: string;
  planPeriod: string;
  planPeriodPrice: number;
  chargeNow: boolean;
  trialPeriodDays: number;
  default: boolean;
  currency: string;
  country: string;
  active: boolean;
  subscriptionGroupId: number;
  variant?: string | null;
  public: boolean;
  lifetimePlan: boolean;
  addonPlan: boolean;
  links: Link;
}

export interface SubscriptionDiscount {
  id: string;
  duration: number;
  amount: number;
  upcomingPlanPrice: number;
  startAt: string;
  endAt?: string;
  type: string;
}

export interface Subscription {
  type: string;
  id: string;
  details?: string | null;
  provider: string;
  providerId: string;
  planId: string;
  discount?: SubscriptionDiscount | null;
  subscribedAt: string;
  periodEndAt: string;
  holdStartAt?: string | null;
  holdEndAt?: string | null;
  trialing: boolean;
  status: string;
  links: Link;
  subscriptionPlan: SubscriptionPlan;
  relationshipNames: string[];
}

export interface UserPaymentSource {
  id: string;
  default: boolean;
  type: string;
  details: {
    brand: string;
    last4: string;
    expMonth: number;
    expYear: number;
  };
}

export type OFFER_REDEMPTION_STATUS = "completed" | "ineligible" | "pending" | "failed";

export interface OfferRedemptions {
  type: string;
  id: number;
  status: OFFER_REDEMPTION_STATUS;
  dateStarted: string;
}
