"use client";
import { datadogRum } from "@datadog/browser-rum";

import { applicationSettings } from "@/constants";

const {
  isDevelopment,
  DATADOG_RUM_APP_ID,
  DATADOG_RUM_CLIENT_TOKEN,
  DATADOG_RUM_SITE,
  DATADOG_RUM_SERVICE,
  DATADOG_RUM_ENV
} = applicationSettings;

export function initDatadogRum() {
  if (!isDevelopment) {
    datadogRum.init({
      applicationId: DATADOG_RUM_APP_ID,
      clientToken: DATADOG_RUM_CLIENT_TOKEN,
      site: DATADOG_RUM_SITE,
      service: DATADOG_RUM_SERVICE,
      env: DATADOG_RUM_ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input"
    });
  }
}

export function logRumError(message: string) {
  if (!isDevelopment) {
    datadogRum.addError(message);
  }
}
