"use client";

import { PropsWithChildren, useState } from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "next-themes";

import { applicationSettings } from "@/constants";
import { Toaster } from "@/ui/shadcn";

const { ENABLE_THEMES } = applicationSettings;

interface ProvidersProps extends PropsWithChildren {}

export const Providers = ({ children }: ProvidersProps) => {
  const [queryClient] = useState(() => new QueryClient());

  const themeSettings = ENABLE_THEMES
    ? {
        enableSystem: true,
        defaultTheme: "system",
        themes: ["light", "dark", "system"]
      }
    : {
        enableSystem: false,
        forcedTheme: "light",
        themes: ["light"]
      };

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider {...themeSettings}>
        {children}
        <Toaster />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
