"use client";
import Image from "next/image";
import Autoplay from "embla-carousel-autoplay";
import { uniqueId } from "lodash-es";
import Link from "next/link";
import useEmblaCarousel from "embla-carousel-react";
import { useEffect } from "react";

import { cn } from "@/ui/utils";
import { ResponsiveContainer } from "@/ui/components";
import { useDotButton } from "@/ui/shadcn/EmblaCarouselDotButton";
import { NextButton, PrevButton, usePrevNextButtons } from "@/ui/shadcn/EmblaCarouselArrowButtons";

const dataCarousel = [
  {
    title: "YOGA’S EMOTIONAL IMPACT: EXPLORE THE REASONS",
    image: "/v2/images/landing/resource-section/askayogi.webp",
    categoryTag: "Yoga basics | Tips for Practicing in Nature",
    link: "https://blog.glo.com/2012/10/why-are-students-overcome-with-emotions-after-yoga-class",
    description:
      "We’ve created a new series, Ask a Yogi, so you can learn more about our YogaGlo teachers by asking them the questions you’ve always wanted to ask..."
  },
  {
    title: "7 SMART PILATES PROP SUBSTITUTES",
    image: "/v2/images/landing/resource-section/pilates_basics.webp",
    categoryTag: "Pilates basics",
    link: "https://blog.glo.com/2020/09/8-clever-substitutions-for-the-most-common-pilates-props",
    description:
      "If a lack of props gets in the way of your home Pilates practice, we've got you covered. Read on for our favorite prop substitutions that can be easily found around the house..."
  },
  {
    title: "MANTRA: A TOOL FOR THE MIND",
    image: "/v2/images/landing/resource-section/mantra.webp",
    categoryTag: "THE LANGUAGE OF YOGA",
    link: "https://blog.glo.com/2019/05/mantra-a-tool-for-the-mind",
    description: `From the Sanskrit root "manas," meaning "mind," and "tra," which translates as "tool," a mantra is literally a tool for the mind. Yoga classes today are full of affirmations and "OMs," however these "mind tools" are anything but new....`
  }
];

export const ResourceSection = () => {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay({ playOnInit: true, delay: 5000 })]);
  const { selectedIndex } = useDotButton(emblaApi);
  const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi);

  useEffect(() => {
    const autoplay = emblaApi?.plugins()?.autoplay;
    if (!autoplay) return;

    emblaApi.on("select", () => {
      if (!autoplay.isPlaying()) {
        autoplay.play();
      }
    });
  }, [emblaApi]);

  return (
    <section id="intentional-living">
      <ResponsiveContainer>
        <div className="px-4 py-16 md:px-0 md:py-[120px]">
          <h3 className="mx-auto mb-16 text-left text-[32px] font-medium leading-tight md:text-center md:text-[40px] lg:mb-[72px] lg:text-5xl">
            Explore more resources for mind-body <br /> well-being from Glo
          </h3>
          <div className="relative px-20">
            <div ref={emblaRef} className="overflow-hidden md:rounded-2xl md:bg-white">
              <div className="flex">
                {dataCarousel.map((item, index) => (
                  <div key={index} className="flex-[0_0_100%] md:px-6 md:py-9 lg:px-7 lg:py-14 xl:px-20">
                    <div className="flex h-full select-none flex-col md:grid md:grid-cols-[40%_60%] md:text-black lg:grid-cols-[33%_67%]">
                      <div className="relative my-0 flex h-auto justify-center max-md:items-center md:my-auto md:size-full">
                        <Link href={item.link} target="_blank">
                          <Image
                            src={item.image}
                            alt={item.title}
                            width={300}
                            height={300}
                            loading="lazy"
                            className={cn(
                              "mx-auto block h-full max-h-[250px] max-md:rounded-[8px] max-md:bg-white md:mx-0 md:w-full lg:max-h-[300px] lg:w-full",
                              index === 0 ? "object-contain max-md:py-5" : "object-cover md:object-cover"
                            )}
                          />
                        </Link>
                      </div>
                      <div className="mx-auto block flex-col justify-center max-md:max-w-[300px] md:flex md:pl-10 xl:pl-16">
                        <Link href={item.link} target="_blank">
                          <div className="text-[8px] uppercase tracking-[1px] max-md:mt-5 max-md:text-center md:text-xs md:tracking-[2px] lg:text-sm">
                            {item.categoryTag}
                          </div>
                          <h4
                            className={cn(
                              "text-center font-medium !leading-tight md:mb-4 md:mt-2 md:text-left md:text-xl md:font-normal md:tracking-[3px] lg:mb-8 lg:mt-4 lg:text-2xl",
                              index === 0 && "max-md:max-w-[250px]"
                            )}
                          >
                            {item.title}
                          </h4>
                          <div className="hidden text-left text-base md:block md:text-lg lg:text-xl">
                            {item.description}
                          </div>
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex-col justify-center max-md:flex">
              <div className="flex px-4 lg:container max-md:order-2 max-md:mt-8 max-md:justify-center max-md:gap-6 md:absolute md:left-1/2 md:top-[calc(50%-32px)] md:w-full md:-translate-x-1/2">
                <PrevButton
                  onClick={onPrevButtonClick}
                  className="-bottom-5 left-4 bg-black/[0.04] !text-[var(--color-cool-grey-700)] dark:bg-white/10 dark:!text-white/70 md:absolute md:bottom-auto md:top-1/2 lg:hover:bg-black/[0.08] lg:hover:text-primary-glo-black dark:lg:hover:bg-white/15"
                />
                <NextButton
                  onClick={onNextButtonClick}
                  className="-bottom-5 right-4 bg-black/[0.04] !text-[var(--color-cool-grey-700)] dark:bg-white/10 dark:!text-white/70 md:absolute md:bottom-auto md:top-1/2 lg:hover:bg-black/[0.08] lg:hover:text-primary-glo-black dark:lg:hover:bg-white/15"
                />
              </div>
              <div className="mt-8 max-md:order-1 md:mt-16">
                <ul className="flex flex-row justify-center gap-2 text-center">
                  {dataCarousel.map((_, index) => (
                    <li
                      key={uniqueId(_.title)}
                      className={cn(
                        "block h-1 w-1 rounded-full bg-[var(--color-cool-grey-600)] dark:bg-[var(--color-cool-grey-700)]",
                        selectedIndex === index && "bg-black dark:bg-white"
                      )}
                    />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ResponsiveContainer>
    </section>
  );
};
