"use client";

import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";
import * as React from "react";

import { cn } from "../utils";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root> & {
    variant?: string;
    label?: React.ReactNode;
    isValid?: string;
  }
>(({ className, variant = "default", label = "", isValid = "false", ...props }, ref) => {
  return variant === "default" ? (
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn("group relative h-6 w-6 disabled:cursor-not-allowed disabled:opacity-50", className)}
      {...props}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="size-full fill-secondary group-aria-checked:fill-primary"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path d="M19 5V19H5V5H19ZM19 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3Z" />
      </svg>
      <CheckboxPrimitive.Indicator className="absolute left-0.5 top-0.5">
        <Check className="size-5" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
  ) : (
    <div className={`relative w-full ${className}`}>
      <CheckboxPrimitive.Root
        ref={ref}
        className={cn("group peer relative h-4 w-4 disabled:cursor-not-allowed disabled:opacity-50")}
        {...props}
      >
        <CheckboxPrimitive.Indicator className="absolute hidden">
          <Check className="cursor-pointer select-none py-[8px] pl-[32px] text-sm leading-none before:absolute before:left-0 before:top-0 before:size-[18px] before:rounded-[3px] before:border before:border-[var(--color-glo-gray-3)] before:bg-white before:content-[''] hover:before:border-black peer-disabled:cursor-not-allowed" />
        </CheckboxPrimitive.Indicator>
      </CheckboxPrimitive.Root>

      <span className="pointer-events-none absolute left-[4px] top-1/2 z-[1] hidden -translate-y-1/2 peer-aria-checked:block">
        <svg xmlns="http://www.w3.org/2000/svg" height="8" viewBox="0 0 20 15" width="10">
          <path
            className="pointer-events-none"
            d="m6.35588403 11.8344519-4.74133031-4.66442953-1.61455372 1.57718121 6.35588403 6.25279642 13.64411597-13.42281879-1.6031836-1.57718121z"
            fillRule="evenodd"
          />
        </svg>
      </span>
      <label
        htmlFor={props.id}
        className={`cursor-pointer select-none pl-[10px] text-sm leading-none before:absolute before:left-0 before:top-1/2 before:size-[18px] before:-translate-y-1/2 before:rounded-[3px] before:border before:border-[var(--color-glo-gray-3)] before:bg-white before:content-[''] hover:before:border-black peer-disabled:cursor-not-allowed ${!isValid ? "border-[var(--text-glo-error)]" : ""}`}
      >
        {label}
      </label>
    </div>
  );
});
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
