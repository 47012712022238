export const GLO_GUIDES_ANALYTICS_EVENTS = {
  TRACK: {
    START_TRIAL: "gloguides_click_startTrial",
    TRIAL_SUBSCRIPTION_SUCCESSFUL: "gloguides_TrialSubscriptionSuccessful",
    SUBMIT_INTAKE_FORM: "gloguides_submit_intakeForm",
    CANCEL: "gloguides_cancel",
    CORE_INTRO_ADD_GLO_GUIDES_SELECTED: "CoreIntroAddGloGuidesSelected",
    PAID_CONVERSION_SUCCESSFUL: "gloguides_PaidConversionSuccessful",
    INTAKE_FORM_STARTED: "gloguides_intakeFormStarted",
    PAYMENT_FAILED: "gloguides_paymentFailed"
  },
  VISIT: {
    PAYWALL: "gloguides_visit_paywall",
    INTAKE_FORM: "gloguides_visit_intakeForm",
    NEW_HOME_PAGE: "gloguides_visit_newHomePage"
  }
};
