import clsx from "clsx";
import { FC, useState, ChangeEvent } from "react";
import { MdMusicNote, MdMusicOff } from "react-icons/md";

import classes from "./SoundMixer.module.scss";

const NO_MUSIC_MIXER_VALUE = 100;

interface SoundMixerProps {
  soundMixerId: string;
  onChange?: (value: number) => void;
}

export const SoundMixer: FC<SoundMixerProps> = ({ onChange, soundMixerId }) => {
  const [showSlider, setShowSlider] = useState(false);
  const [volume, setVolume] = useState(50);
  const [beforeMuteValue, setBeforeMuteValue] = useState(volume);
  const [isMuted, setIsMuted] = useState(false);

  const displaySlider = () => setShowSlider(true);
  const hideSlider = () => setShowSlider(false);

  const updateMixerValue = (newValue: number) => {
    setVolume(newValue);
    if (onChange) onChange(newValue);
  };

  const handleButtonClick = () => {
    if (isMuted) {
      updateMixerValue(beforeMuteValue);
    } else {
      setBeforeMuteValue(volume);
      updateMixerValue(NO_MUSIC_MIXER_VALUE);
    }
    setIsMuted(!isMuted);
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value, 10);

    updateMixerValue(newValue);
    setIsMuted(false);
  };

  const iconStyles = {
    color: "white",
    height: 22,
    width: 24
  };

  const audioMixerIcon =
    isMuted || volume === NO_MUSIC_MIXER_VALUE ? <MdMusicOff style={iconStyles} /> : <MdMusicNote style={iconStyles} />;

  return (
    <div
      id={soundMixerId}
      role="group"
      tabIndex={0}
      className={classes["sound-mixer"]}
      onFocus={displaySlider}
      onMouseLeave={hideSlider}
    >
      <div className={classes["sound-mixer__mixer-btn"]} onClick={handleButtonClick} onMouseEnter={displaySlider}>
        {audioMixerIcon}
      </div>

      <div
        className={clsx(classes["sound-mixer-slider"], {
          [classes["sound-mixer-slider--show"]]: showSlider
        })}
        onMouseEnter={() => setShowSlider(true)}
        onMouseLeave={() => setShowSlider(false)}
      >
        <span>MUSIC&nbsp;</span>
        <input
          className={classes["video-mixer-input"]}
          id="video-mixer-input"
          value={volume}
          type="range"
          step={1}
          aria-valuemin={1}
          aria-valuemax={100}
          aria-label="Audio Mix Balance"
          onChange={handleInputChange}
        />
        <span>&nbsp;VOICE</span>
      </div>
    </div>
  );
};
