"use client";

import { FC, useState } from "react";

interface ShowMoreOrLessProps {
  text: string;
  maxLength?: number;
}

export const ShowMoreOrLess: FC<ShowMoreOrLessProps> = ({ text, maxLength = 500 }) => {
  const [showMore, setShowMore] = useState(false);

  if (text?.length <= maxLength) {
    return <span>{text}</span>;
  }

  return (
    <span>
      {showMore ? text : `${text?.substring(0, maxLength)}...`}
      <span className="cursor-pointer pl-1 font-sanserif font-semibold" onClick={() => setShowMore(!showMore)}>
        {showMore ? "show less" : "show more"}
      </span>
    </span>
  );
};
