"use client";
import { usePathname } from "next/navigation";

import { applicationSettings } from "@/constants";
import { User } from "@/types";

import { DesktopMenu } from "./DesktopMenu";
import { MobileMenu } from "./MobileMenu";
import { ThemeToggle } from "./ThemeToggle";
import { UserAvatar } from "./UserAvatar";
import { LogoGlo } from "./LogoGlo";
import { NavBarHome } from "./NavBarHome";

const { ENABLE_THEMES } = applicationSettings;
export const NavBarContent = ({ user }: { user: User | null }) => {
  const pathname = usePathname();
  const isHomePage = pathname === "/v2/home";

  return isHomePage ? (
    <NavBarHome />
  ) : (
    <>
      <MobileMenu user={user} />
      <LogoGlo />
      <div className="flex flex-row items-center justify-end md:w-full md:gap-8">
        <DesktopMenu user={user} />
        {ENABLE_THEMES && <ThemeToggle />}
        <UserAvatar user={user} />
      </div>
    </>
  );
};
