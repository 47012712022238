import { useCallback } from 'react';

import useOptimizelyClient from '@/hooks/analytics/useOptimizelyClient';

const useOptimizelyEvents = () => {
  const optimizelyClient = useOptimizelyClient();

  const loadEvents = useCallback(() => {
    const projectConfig = optimizelyClient?.getOptimizelyConfig();
    const events = projectConfig?.events.map((e) => e.key);
    return events;
  }, [optimizelyClient]);

  return loadEvents;
};

export default useOptimizelyEvents;
