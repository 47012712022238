"use client";
import Image from "next/image";
import { useEffect } from "react";

import useAnalyticsGloGuides from "@/hooks/analytics/useAnalyticsGloGuides";

export const BannerLandingPage = () => {
  const { trackNewHomePage } = useAnalyticsGloGuides();
  useEffect(() => {
    trackNewHomePage();
  }, []);

  return (
    <section id="banner-landing-page">
      <div className="relative bg-white dark:bg-surface-primary">
        <div className="relative 2xl:pb-[60%]">
          <div className="absolute left-0 top-6 z-10 w-full md:top-[34px] lg:top-16">
            <div className="responsive-container lg:container md:px-8">
              <h1 className="px-4 font-amerigo text-[40px] font-medium leading-[1.1] text-black md:px-0 md:text-[52px] xl:text-[64px]">
                Feel good <br /> inside and out
              </h1>
            </div>
          </div>
          <picture>
            <source
              media="(max-width: 768px)"
              srcSet="/v2/images/landing/banner/patrick-malleret-mobile.webp"
              type="image/webp"
            />
            <Image
              src="/v2/images/landing/banner/patrick-malleret.webp"
              alt="Feel good inside and out"
              width={1920}
              height={1024}
              quality={100}
              priority={true}
              loading="eager"
              className="size-full object-cover max-sm:h-[645px] md:max-h-[768px] lg:max-h-[1024px] 2xl:absolute 2xl:max-h-full"
            />
          </picture>
        </div>
        <div className="responsive-container lg:container md:px-8">
          <div className="px-4 py-16 md:flex md:gap-16 md:px-0 md:py-[120px]">
            <h2 className="text-[32px] font-medium leading-tight max-md:mb-12 md:basis-1/2 md:text-[40px] xl:text-5xl">
              Welcome to Glo, where intention is at the heart of everything
            </h2>
            <div className="text-base md:basis-1/2 md:text-xl xl:text-2xl">
              We believe your practice is more than a path to fitness — it empowers you to live fully. Glo brings
              awareness to how yoga and mindful movement can elevate your daily life. With expert instruction blending
              ancient traditions with modern insights, we support you in every stage. Join us to cultivate a practice
              designed to help you feel good inside and out.
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
