"use client";
import Image from "next/image";

import { Button } from "@/ui/shadcn";
import { applicationSettings } from "@/constants";
const { LEGACY_WEBSITE_URL } = applicationSettings;

export const GloGuides = () => {
  const handleRegisterClick = () => {
    window.open(`${LEGACY_WEBSITE_URL}/register`, "_blank", "noopener,noreferrer");
  };
  const handleStartTrialClick = () => {
    window.open(`${LEGACY_WEBSITE_URL}/v2/glo-guides/start-your-trial`, "_blank", "noopener,noreferrer");
  };
  return (
    <section id="glo-guides" className="bg-white dark:bg-surface-primary">
      <div className="grid lg:grid-cols-2">
        <div className="relative lg:order-2">
          <Image
            src="/v2/images/landing/glo-guides/glo-guides.webp"
            alt="Glo Guides"
            width={700}
            height={700}
            loading="lazy"
            className="size-full object-cover"
          />
        </div>
        <div className="flex flex-col justify-center px-4 pb-16 pt-10 md:px-8 md:pt-16 lg:order-1 lg:px-16 lg:py-8">
          <div className="mb-4 text-[10px] font-medium uppercase tracking-[4px] md:mb-6 md:text-sm xl:text-base">
            Introducing:
          </div>
          <h3 className="mb-3.5 indent-[-4px] text-[56px] font-medium !leading-[40px] tracking-[-2px] md:mb-6 xl:-indent-2 xl:text-8xl xl:!leading-[70px]">
            Glo Guides
          </h3>
          <div className="mb-12 text-base font-medium md:mb-16 xl:text-2xl">
            Personal coaching dedicated to your well-being
          </div>
          <p className="mb-12 text-base md:mb-16 md:text-xl xl:text-2xl">
            Get custom support from a skilled coach who tailors every step of your practice to fit your unique needs.
            <br />
            <br />
            Whether you’re resolving to build consistency or explore something new, Glo Guides provides the insight and
            inspiration you need to thrive on and off the mat. Try it free for 14 days.
          </p>
          <Button className="w-fit !pr-8" onClick={handleStartTrialClick}>
            Learn more
          </Button>
        </div>
      </div>
      <div className="grid lg:grid-cols-2">
        <div className="relative pb-[100%] lg:pb-0">
          <Image
            src="/v2/images/landing/glo-guides/why-glo.webp"
            alt="Why Glo"
            width={700}
            height={500}
            loading="lazy"
            className="absolute !size-full md:object-cover lg:static"
          />
        </div>
        <div className="flex flex-col justify-center px-4 py-16 md:px-8 md:py-32 lg:px-16 lg:py-8">
          <h3 className="mb-16 text-[32px] font-medium leading-none md:text-[40px] xl:text-5xl">Why Glo?</h3>
          <p className="mb-16 text-base md:text-xl xl:text-2xl">
            We believe your practice is more than a path to fitness — it empowers you to live fully. Glo brings
            awareness to how yoga and mindful movement can elevate your daily life. With expert instruction blending
            ancient traditions with modern insights, we support you in every stage. Join us to cultivate a practice
            designed to help you feel good inside and out.
          </p>
          <Button className="w-fit !pr-8" onClick={handleRegisterClick}>
            Start now
          </Button>
        </div>
      </div>
    </section>
  );
};
